'use client';
import { PlusIcon } from '@primer/octicons-react';
import { IconButton, Spinner } from '@primer/react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { MouseEventHandler } from 'react';
import { useCallback, useEffect, useState } from 'react';

import type { SidebarContentProps } from '@/components/SidebarContent';

export interface SidebarNavProps extends Omit<SidebarContentProps, 'groups'> {}

export const AddQuestSmallSidebarButton = () => {
  // https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
  // This sucks because it means there's no way to detect errors or possible intermediate redirects.

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const destPath = '/quests/new';
  const currentPath = `${pathname}${searchParams.size ? `?${searchParams.toString()}` : ''}`;

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading && currentPath === destPath) {
      setLoading(false);
    }
  }, [isLoading, setLoading, currentPath, destPath]);

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!isLoading) {
        setLoading(true);
        void router.push(destPath);
      }
    },
    [router, isLoading, destPath]
  );

  return (
    <IconButton
      as="button"
      icon={isLoading ? () => <Spinner size="small" /> : PlusIcon}
      size="small"
      aria-label="Create Quest"
      onClick={onClick}
    />
  );
};

export default AddQuestSmallSidebarButton;
