'use client';

import type React from 'react';
import { useEffect, useMemo } from 'react';

/**
 * `useEffect`, with some delay.
 */

export const useDelayedEffect = (
  delayMs: number | null | undefined,
  fn: React.EffectCallback,
  deps: React.DependencyList = []
): void => {
  delayMs = delayMs ?? 0;
  const givenFn = fn;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  fn = useMemo(() => givenFn, deps);

  useEffect(() => {
    if (!delayMs) {
      return fn();
    }

    let cleanup: ReturnType<React.EffectCallback> | undefined;
    let timer: ReturnType<typeof setTimeout> | undefined = setTimeout(() => {
      cleanup = fn();
    }, delayMs);
    return () => {
      if (timer != null) {
        clearTimeout(timer);
        timer = void 0;
      }
      if (cleanup) {
        cleanup();
        cleanup = void 0;
      }
    };
  }, [fn, delayMs]);
};
