'use client';

import type React from 'react';
import { useEffect } from 'react';

import { useDelayedEffect } from '@/components/useDelayedEffect';

const hasLocalStorage = typeof globalThis.localStorage !== 'undefined';

export interface UseLocalFlag {
  <T = string>(name: string, parse?: (_: string) => T): T | null;
  <T = string, D = T>(name: string, parse: (_: string) => T, defaultValue: D): T | D;
}

// Read a flag from localStorage, if available.
export const useLocalFlag = hasLocalStorage
  ? ((<T = string>(name: string, parse?: (_: string) => T, defaultValue = null): T | null => {
      const value = globalThis.localStorage.getItem(name);
      if (value == null) {
        return defaultValue;
      }
      return parse != null ? parse(value) : (value as T);
    }) as UseLocalFlag)
  : () => void 0;

const isDevable = process.env.NODE_ENV !== 'production' && hasLocalStorage;

export type UseDevFlag = UseLocalFlag;

// Read a flag from localStorage, if available, and not in production.
export const useDevFlag = isDevable ? useLocalFlag : () => void 0;

/**
 * `useEffect`, possibly delayed by a flag in localStorage.
 */
export const useEffectWithDevDelay: (flagName: string, fn: React.EffectCallback, deps?: React.DependencyList) => void =
  isDevable
    ? (flagName, fn, deps) => {
        useDelayedEffect(
          useDevFlag(flagName, n => parseInt(n, 10)),
          fn,
          deps
        );
      }
    : // eslint-disable-next-line react-hooks/exhaustive-deps
      (_, fn, deps) => void useEffect(fn, deps);
